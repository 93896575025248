const initialState = {isFetching:false};

const siteReducer = (state = initialState, action) => {
	switch (action.type) {
		case "UPDATE_SITE":
			return { ...state, ...action.payload };
			break;
		case "CLEAR_SITE":
			return initialState
			break;
		case "IS_FETCHING":
			return {...state, isFetching: action.status}
			break;
		default:
			return state;
			break;
	}
};

export default siteReducer;
