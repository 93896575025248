const pageReducer = (state = { list: [], currentPage: {} }, action) => {
	switch (action.type) {
		case "UPDATE_PAGES":
			return { ...state, list: action.payload };
		case "UPDATE_DATA_PAGES": {
			let newList = state.list.map(page => {
				let res = action.payload.filter(e => e.id === page.id);
				if (res.length > 0) {
					return { ...page, ...res[0] };
				} else {
					return page;
				}
			});
			return { ...state, list: newList };
		}
		case "UPDATE_DATA_PAGE": {
			let newList = state.list.map(page => {
				if (page.id === action.payload.id) {
					return { ...page, ...action.payload };
				} else {
					return page;
				}
			});

			// update also current page if needed
			let currentPage =
				action.payload.id === state?.currentPage?.id ? { ...state.currentPage, ...action.payload } : state.currentPage;

			return { ...state, list: newList, currentPage };
		}
		case "CHANGE_CURRENT_PAGE": {
			let currentPage = state.list.find(p => p.id === action.payload);
			return currentPage ? { ...state, currentPage } : state;
		}
		case "ADD_PAGE":
			return { ...state, list: [...state.list, action.payload] };
		default:
			return state;
	}
};

export default pageReducer;
