import React from "react";
import * as Icon from "react-feather";

const horizontalMenuConfig = [
  {
    id: "website",
    title: "SITE INTERNET",
    type: "dropdown",
    icon: <Icon.CheckSquare size={16} />,
    children: [
      {
        id: "general",
        title: "Général",
        type: "item",
        icon: <Icon.CheckSquare size={20} />,
        navLink: "/website/general",
      },
      {
        id: "appearance",
        title: "Apparence",
        type: "item",
        icon: <Icon.CheckSquare size={20} />,
        navLink: "/website/appearance",
      },
    ],
  },
];

export default horizontalMenuConfig;
