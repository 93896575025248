import axios from "../../../utility/api";
export const changeMode = (mode) => {
	return (dispatch) => dispatch({ type: "CHANGE_MODE", mode });
};

export const collapseSidebar = (value) => {
	return (dispatch) => dispatch({ type: "COLLAPSE_SIDEBAR", value });
};

export const changeNavbarColor = (color) => {
	return (dispatch) => dispatch({ type: "CHANGE_NAVBAR_COLOR", color });
};

export const changeNavbarType = (style) => {
	return (dispatch) => dispatch({ type: "CHANGE_NAVBAR_TYPE", style });
};

export const changeFooterType = (style) => {
	return (dispatch) => dispatch({ type: "CHANGE_FOOTER_TYPE", style });
};

export const changeMenuColor = (style) => {
	return (dispatch) => dispatch({ type: "CHANGE_MENU_COLOR", style });
};

export const hideScrollToTop = (value) => {
	return (dispatch) => dispatch({ type: "HIDE_SCROLL_TO_TOP", value });
};

export const fetchThemeConfig = () => async (dispatch, getState) => {
	try {
		let user = getState().auth.user;

		if (user) {
			let newConfig = await axios
				.get(`/api/users/${user.id}/theme_preference`)
				.then((res) => res.data);
			
		    delete newConfig["@context"];
			delete newConfig["@id"];
			delete newConfig["@type"];
			Object.keys(newConfig).forEach((key) => {
				if (!newConfig[key]) {
					delete newConfig[key];
				}
			});
			
			dispatch(changeThemeConfig(newConfig));
		}
	} catch (error) {
		console.log("error fetching theme config", error);
		return error;
	}
};

export const changeThemeConfig = (config) => {
	return { type: "CHANGE_THEME_CONFIG", config };
};
