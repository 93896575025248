import React from "react";
import { User } from "react-feather";

const Avatar = ({
  src,
  alt,
  className,
  size,
  componentProps = {},
  ...props
}) => {
  return (
    <div className={`avatar m-0 ${className ? className : ""}`} {...props}>
      {src ? (
        <img
          src={src}
          alt={alt ? alt : ""}
          width={size}
          height={size}
          {...componentProps}
        />
      ) : (
        <User style={{ width: size, height: size }} {...componentProps} />
      )}
    </div>
  );
};

export default Avatar;
