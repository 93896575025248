import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { changeLang } from "../../../redux/actions/traduction";
import { updateCurrentUser } from "../../../redux/actions/auth";
import { langsToIcons } from "../../../configs/traduction";
import { useTrad } from "../../../utility/traduction";
import styled from "styled-components";

const CustomDropdownItem = styled(DropdownItem)`
	&& {
		width: 100% !important;
	}

	&&:hover {
		background-color: #f0f6ff !important;
	}
`;

const CustomDropdownMenu = styled(DropdownMenu)`
	&& {
		// /* width */
		// ::-webkit-scrollbar {
		// 	width: 10px;
		// }

		// /* Track */
		// ::-webkit-scrollbar-track {
		// 	background: #f1f1f1;
		// }

		// /* Handle */
		// ::-webkit-scrollbar-thumb {
		// 	background: #4889E3;
		// 	border-radius: 10px;
		// }

		// /* Handle on hover */
		// ::-webkit-scrollbar-thumb:hover {
		// 	background: #659be6;
		// }
		// max-height: 300px;
		// overflow-y: scroll;
	}
`;
const NavbarLangs = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(prevState => !prevState);
	const currLang = useSelector(state => state.traduction.lang);
	const dispatch = useDispatch();
	const t = useTrad("HibimSiteAdmin_header");

	const handleSwitchLang = keyLang => {
		// update User lang as well
		dispatch(updateCurrentUser({languages:[keyLang]}))
		dispatch(changeLang(keyLang));
	};

	return (
		<div>
			<Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-language nav-item">
				<DropdownToggle tag="a" className="d-flex align-items-center nav-link">
					<ReactCountryFlag
						countryCode={langsToIcons[currLang] ? langsToIcons[currLang].key : "fr"}
						svg
						style={{ width: "2em", marginRight: "7px" }}
					/>{" "}
					{langsToIcons[currLang] ? t(currLang, langsToIcons[currLang].text, true) : t("fr", "Français", true)}
				</DropdownToggle>
				<CustomDropdownMenu>
					{Object.keys(langsToIcons)
						.filter(keyLang => keyLang !== currLang)
						.map(keyLang => {
							return (
								<CustomDropdownItem
									key={keyLang}
									className="d-flex align-items-center"
									onClick={() => handleSwitchLang(keyLang)}
								>
									<ReactCountryFlag
										countryCode={langsToIcons[keyLang].key}
										svg
										style={{ width: "1.5em", marginRight: "7px" }}
									/>{" "}
									{t(keyLang, langsToIcons[keyLang].text, true)}
								</CustomDropdownItem>
							);
						})}
				</CustomDropdownMenu>
			</Dropdown>
		</div>
	);
};

export default NavbarLangs;

