const rootUrl = process.env.REACT_APP_SITE_URL;

const iframeReducer = (state = { url: "", refresh: 0 }, action) => {
	switch (action.type) {
		case "PREVIEW_THEME":
			return {
				...state,
				url: `${state.url}?preview=true&theme=${action.payload}`,
			};
		case "STOP_PREVIEW":
			return { ...state, url: state.url.split("?")[0] };
		case "REFRESH":
			return { ...state, refresh: state.refresh + 1 };
		case "CHANGE_SPACE":
			return { ...state, url: `${rootUrl}/${action.payload}` };
		default:
			return state;
	}
};
export default iframeReducer;
