import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import traduction from "./traduction"
import site from "./site"
import spaces from "./space"
import iframe from "./iframe"
import pages from "./pages"

const rootReducer = combineReducers({
  customizer,
  auth,
  traduction, 
  site, 
  spaces,
  iframe,
  pages
});

export default rootReducer;
