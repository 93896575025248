import { REQUEST_AUTH, SUCCESS_AUTH, ERROR_AUTH, CLEAR, GET_CURRENT_USER, SET_CURRENT_USER } from "../../actions/auth";

const connect = (state = {}, action) => {
  switch (action.type) {
    case SUCCESS_AUTH:
      return {
        ...state,
        ...action.auth,
      };

    case REQUEST_AUTH:
      return {
        ...state,
        ...action.auth,
      };

    case ERROR_AUTH:
      return {
        ...state,
        ...action.auth,
      };

    case CLEAR:
      return {
        ...state,
        ...action.auth,
      };

    case GET_CURRENT_USER:
      if (action.success === null) {
        return {
          ...state,
          userLoading: true,
        };
      }

      if (!action.success) {
        return {
          ...state,
          userLoading: false,
        };
      }
      let instance = null;
      if (action.data && action.data["hydra:member"] && action.data["hydra:member"].length) {
        instance = action.data["hydra:member"][0];
      }
      return {
        ...state,
        user: instance,
        userLoading: false,
      };

    case SET_CURRENT_USER: {
      return { ...state, user: { ...state.user, ...action.payload } };
    }
    default:
      return state;
  }
};

export default connect;
