import React from "react";
import * as Icon from "react-feather";
import "boxicons"
const navigationConfig = [
  {
    id: "mySpace",
    title: "Retour à My Space",
    type: "external-link",
    iconName:'chevron-left',
    icon: <box-icon name='chevron-left' color="#565656"></box-icon>,
    navLink: process.env.REACT_APP_SPACE_URL,
  },
  {
    id: "website",
    type: "groupHeader",
    groupTitle: "SITE INTERNET",
  },
  {
    id: "visitWebsite",
    title: "Visiter le site web",
    type: "external-link",
    iconName:"file-blank",
    icon: <box-icon name='file-blank' color="#565656"></box-icon>,
    positionIcon:"right",
    navLink: process.env.REACT_APP_SPACE_URL,
    newTab:true
  },
  {
    id: "general",
    title: "Général",
    type: "item",
    iconName:"home-smile",
    icon: <box-icon name='home-smile' color="#565656"></box-icon>,
    navLink: "/website/general",
  },
  {
    id: "apparence",
    title: "Apparence",
    type: "item",
    iconName:"layout",
    icon: <box-icon name='layout' color="#565656"></box-icon>,
    navLink: "/website/appearance",
  },
];

export default navigationConfig;
