import React, { useEffect } from "react";
import Router from "./Router";
import "./components/@vuexy/rippleButton/RippleButton";

import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import { useSelector, useDispatch } from "react-redux";
import { authPending, isLogged, authenticate } from "./redux/actions/auth";
import { changeLang } from "./redux/actions/traduction";
import { fetchSpaces } from "./redux/actions/space";
import { fetchSite } from "./redux/actions/site";
import { fetchPages } from "./redux/actions/pages";
import { fetchThemeConfig } from "./redux/actions/customizer";
import { changeSpace } from "./redux/actions/iframe";
import { acceptedLocales } from "./configs/traduction";

const App = props => {
  const { pending, logged, auth, spaces } = useSelector(state => ({
    pending: authPending(state),
    logged: isLogged(state),
    auth: state.auth,
    spaces: state.spaces,
  }));

  const spaceParam = new URLSearchParams(window?.location?.search).get("space");

  const dispatch = useDispatch();
  const handleAuthentication = React.useCallback(() => dispatch(authenticate()), [dispatch]);

  // TODO: uncomment this when preprod is ok
  useEffect(() => {
    if (pending === undefined && logged === undefined) {
      handleAuthentication();
    }
    if (pending === false && logged === false) {
      if (process.env.REACT_APP_ACCOUNTS_URL) {
        window.location.replace(
          `${process.env.REACT_APP_ACCOUNTS_URL}?redirect=${encodeURIComponent(window.location.href)}`,
        );
      }
    }
  }, [pending, logged, handleAuthentication]);

  useEffect(() => {
    // fetch spaces & theme config only if user is logged in and current user exists
    if (logged && auth.user) {
      dispatch(fetchSpaces(spaceParam));
      dispatch(fetchThemeConfig());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged, auth.user, dispatch]);

  useEffect(() => {
    if (auth?.user?.languages) {
      let locale = auth.user.languages[0];
      if (locale && acceptedLocales.includes(locale)) {
        dispatch(changeLang(locale));
      }
    }
  }, [auth.user, dispatch]);

  useEffect(() => {
    let { slug } = spaces.currentSpace;
    if (slug) {
      dispatch(changeSpace(slug));
    }
  }, [dispatch, spaces.currentSpace]);

  useEffect(() => {
    if (auth.user && spaces.currentSpace.id) {
      dispatch(fetchSite());
      dispatch(fetchPages());
    }
  }, [auth.user, dispatch, spaces.currentSpace.id]);

  return <Router />;
};

export default App;
