import React from "react";
import { NavLink } from "react-router-dom";
import { Disc, Circle } from "react-feather";


const SidebarHeader = ({
  menuShadow,
  toggleSidebarMenu,
  toggle,
  collapsed,
  sidebarState,
}) => {
  return (
    <div className={`navbar-header ${collapsed && sidebarState ? "mini" : ""}`}>
      <ul className="nav navbar-nav flex-row align-items-center">
        <li className="nav-item mr-auto">
          <NavLink to="/" className="navbar-brand">
            <div
              className={
                collapsed && sidebarState ? "brand-logo-mini" : "brand-logo"
              }
            />
            <h2 className="brand-text text-light mb-0 truncate" style={{fontSize:"0.65em"}}>Site</h2>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle align-middle">
          {collapsed === false ? (
            <Disc
              onClick={() => {
                toggleSidebarMenu(true);
                toggle();
              }}
              size={20}
              className=" navbar-brand d-none d-xl-block text-primary"
            />
          ) : (
            <Circle
              onClick={() => {
                toggleSidebarMenu(false);
                toggle();
              }}
              size={20}
              className=" navbar-brand d-none d-xl-block text-primary"
            />
          )}
        </li>
      </ul>
    </div>
  );
};

export default SidebarHeader;
