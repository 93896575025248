import axios from "../../../utility/api";

export const updateSite = (data) => {
	return { type: "UPDATE_SITE", payload: data };
};

export const clearSite = () => {
	return { type: "CLEAR_SITE"};
};


export const createOrUpdateSite = (data) => async (dispatch, getState) => {
	const site = getState().site || {};
	const spaceId = getState().spaces.currentSpace.id;
	const siteId = site.id		
	try {
		if (!siteId) {
			if (spaceId) {
				const newData = { space: `/api/space/${spaceId}`, ...data };
				let res = await axios.post("/api/sites", newData).then(res => res.data);
				const toDelete = ["@id", "@type", "@context", "space"]
				toDelete.forEach(key => {delete res[key]})
				dispatch(updateSite(res));
			} else {
				console.log("No space Id exists")
				return {error:"No space Id exists"}
			}
		} else {
			let res = await axios.put(`/api/sites/${siteId}`, data).then(res => res.data);
			const toDelete = ["@id", "@type", "@context", "space"]
			toDelete.forEach(key => {delete res[key]})
			dispatch(updateSite(res));
		}
	} catch (e) {
		console.log("error createOrUpdateSite", e)
		return {error:e}
	}
};



export const fetchSite = () => async (dispatch, getState) => {
	const spaceId = getState().spaces.currentSpace.id;
	dispatch({type:"IS_FETCHING", status:true});
	try {
		if (spaceId) {
				let res = await axios.get(`/api/spaces/${spaceId}/site`).then(res => res.data);
				const toDelete = ["@id", "@type", "@context", "space"]
				toDelete.forEach(key => {delete res[key]})
				res.theme = res.theme || "bootsland"
				dispatch(updateSite(res));
		} else {
			console.log("no space Id");
		}
	} catch (e) {
		console.log("error createOrUpdateSite", e)
		dispatch(clearSite());
	}
	dispatch({type:"IS_FETCHING", status:false});
};


// data : {h1 : {id, color,...}, h2, paragraph}
export const updateFont = data => async (dispatch, getState) => {
  try {
    let tags = Object.keys(data); // [h1, h2,...]
    let site = getState()?.site;
    let siteFont = site.siteFont || {};
    // Font ressource exists
    let fontId = siteFont?.id;
    if (!fontId) {
      // Create FONT ressource
      fontId = await axios.post("/api/fonts", {}).then(res => res.data.id);
      // add FONT ressource id to SITE
      await axios.put(`/api/sites/${site.id}`, { siteFont: "/api/fonts/" + fontId });
    }

    for (let i = 0; i < tags.length; i++) {
      let tag = tags[i];
      let dataTag = data[tag];
      let tagId = siteFont && siteFont[tag] && siteFont[tag].id
      if (tagId) {
        // update element style
        await axios.put("/api/element_styles/" + tagId, dataTag);
      } else {
        // create element style
        let elemId = await axios.post("/api/element_styles", dataTag).then(res => res.data.id);
        // ADD elementStyle id to FONT
        await axios.put("/api/fonts/" + fontId, { [tag]: "/api/element_styles/" + elemId });
      }
    }

    await dispatch(fetchSite());
    return { status: "success" };
  } catch (e) {
    console.log("error creating link", e);
    return { error: e };
  }
};
