import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  Archive,
  ChevronDown
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {useTrad} from "../../../../../utility/traduction"
import {setCurrentSpace} from "../../../../../redux/actions/space"
const SidebarSpaces = () => {
 
  const t = useTrad("HibimSiteAdmin_menu_SidebarSpaces")
  const spaces = useSelector(state => state.spaces.list || [])
  const currentSpace = useSelector(state => state.spaces.currentSpace || {})
  const dispatch = useDispatch();

  const changeCurrentSpace = (space) =>
  {
    dispatch(setCurrentSpace(space));
  }

  return (
    <div className="space-action d-block px-1">
      <UncontrolledDropdown>
        <DropdownToggle color="tag primary" className="w-100">
          <div className="d-flex align-items-center">
            <Archive size={20} />
            <span className="ml-1 mr-auto text-bold-600">
              {currentSpace && currentSpace.name}
            </span>
            <ChevronDown size={20} />
          </div>
        </DropdownToggle>
        <DropdownMenu right style={{ maxWidth: "230px" }}>
          <DropdownItem header>{t("mySpaces","Mes Spaces")}</DropdownItem>
          {spaces.map((space, id) => (
            <DropdownItem
              toggle={false}
              key={id}
              tag="a"
              className="d-flex align-items-center"
              onClick={() => changeCurrentSpace(space)}
            >
              <div className="mr-50">
                <Archive size={15} />
              </div>
              <span className="truncate">{space.name}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default SidebarSpaces;
