import axios from "../../../utility/api";
import { uid } from "uid";

export const updatePages = pages => ({
  type: "UPDATE_PAGES",
  payload: pages,
});

export const updateDataPages = data => ({
  type: "UPDATE_DATA_PAGES",
  payload: data,
});

export const updateDataPage = data => ({
  type: "UPDATE_DATA_PAGE",
  payload: data,
});

export const addPage = data => ({
  type: "ADD_PAGE",
  payload: data,
});

/**
 * Update state currentPage
 * @param id Page's id
 */
export const changeCurrentPage = id => ({
  type: "CHANGE_CURRENT_PAGE",
  payload: id,
});

export const fetchPage = id => async (dispatch, getState) => {
  try {
    let currentSpaceId = getState().spaces.currentSpace.id;
    let page = await axios.get(`/api/pages/${id}`).then(res => res.data);
    dispatch(updateDataPage(page));
  } catch (e) {
    console.log("error fetching page", e);
  }
};

export const fetchPages = () => async (dispatch, getState) => {
  try {
    let currentSpaceId = getState().spaces.currentSpace.id;
    let res = await axios.get(`/api/spaces/${currentSpaceId}/pages`).then(res => res?.data["hydra:member"]);

    const toDelete = ["@id", "@type", "space"];
    toDelete.forEach(e => {
      delete res[e];
    });
    dispatch(updatePages(res));
  } catch (e) {
    console.log("error fetching pages", e);
  }
};

export const deletePages = pages => async (dispatch, getState) => {
  try {
    let ids = pages.map(page => page.id);
    for (let i = 0; i < ids.length; i++) {
      await axios.delete(`/api/pages/${ids[i]}`);
    }
    let newPages = getState().pages.list.filter(page => !ids.includes(page.id));
    dispatch(updatePages(newPages));
  } catch (e) {
    console.log("error deleting pages", e);
    return { error: e };
  }
};

export const activatePages = (pages, shouldDraft = false) => async (dispatch, getState) => {
  try {
    let newStatus = shouldDraft ? "draft" : "published";
    let ids = pages.filter(page => page.status !== newStatus).map(page => page.id);
    for (let i = 0; i < ids.length; i++) {
      await axios.put(`/api/pages/${ids[i]}`, { status: newStatus });
    }
    let newPages = getState().pages.list.map(page => {
      if (ids.includes(page.id)) {
        page.status = newStatus;
      }
      return page;
    });
    dispatch(updatePages(newPages));
  } catch (e) {
    console.log("error activating pages", e);
    return { error: e };
  }
};

export const updateMultiplePages = pagesData => async dispatch => {
  try {
    for (let i = 0; i < pagesData.length; i++) {
      let pageData = pagesData[i];
      await axios.put(`/api/pages/${pageData.id}`, pageData);
    }
    dispatch(updateDataPages(pagesData));
  } catch (e) {
    console.log("error updating multiple pages", e);
    return { error: e };
  }
};

export const updatePage = page => async dispatch => {
  try {
    await axios.put(`/api/pages/${page.id}`, page);
    dispatch(updateDataPage(page));
  } catch (e) {
    console.log("error updating page", e);
    return { error: e };
  }
};

export const createLink = link => async (dispatch, getState) => {
  try {
    console.log("create link !");
    let spaceId = getState().spaces.currentSpace.id;
    let dataLink = {
      ...link,
      slug: `link-${uid(8)}`,
      status: "published",
      template: "custom",
      space: `/api/space/${spaceId}`,
    };
    let data = await axios.post("/api/pages", dataLink).then(res => res.data);
    dispatch(addPage(data));
    return { status: "success", data };
  } catch (e) {
    console.log("error creating link", e);
    return { error: e };
  }
};

// data : {h1 : {id, color,...}, h2, paragraph}
export const updateFont = (data, mode = "desktop") => async (dispatch, getState) => {
  try {
    let tags = Object.keys(data); // [h1, h2,...]
    let page = getState()?.pages?.currentPage;
    let keyPage = mode === "desktop" ? "pageFont" : mode === "tablet" ? "pageFontTablet" : "pageFontMobile";
    let pageFont = page[keyPage] || {};
    // Font ressource exists
    let fontId = pageFont?.id;
    if (!fontId) {
      // Create FONT ressource
      fontId = await axios.post("/api/fonts", {}).then(res => res.data.id);
      // add FONT ressource id to PAGE
      await axios.put(`/api/pages/${page.id}`, { [keyPage]: "/api/fonts/" + fontId });
    }

    for (let i = 0; i < tags.length; i++) {
      let tag = tags[i]; // h1
      let dataTag = data[tag]; // {color : "black", textTransform:"none", ...}
      let tagId = pageFont && pageFont[tag] && pageFont[tag].id;
      if (tagId) {
        // update element style
        await axios.put("/api/element_styles/" + tagId, dataTag);
      } else {
        // create element style
        let elemId = await axios.post("/api/element_styles", dataTag).then(res => res.data.id);
        // ADD elementStyle id to FONT
        await axios.put("/api/fonts/" + fontId, { [tag]: "/api/element_styles/" + elemId });
      }
    }

    await dispatch(fetchPage(page.id));
    return { status: "success" };
  } catch (e) {
    console.log("error creating link", e);
    return { error: e };
  }
};
